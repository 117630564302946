import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../atoms/layout';
import StatsList from '../StatsList';
import Section, { Article, LayoutQuote, ImgLayout } from './HomeTestimonial';
import { Quote2 } from '../atoms/quotes';
import { Text5 } from '../atoms/body';
import { TDatoCmsImage } from '../../types/datocms';
import QuoteMarks from '../../../static/assets/quote/quotation-marks.svg';

type TmageQuoteProps = {
  img: TDatoCmsImage;
};

const ImageQuote = (props: TmageQuoteProps): ReactElement => {
  const { img } = props;
  const { t } = useTranslation(['home']);

  return (
    <Layout extend={LayoutQuote}>
      <StatsList context="home" />
      <Section>
        <ImgLayout>
          <GatsbyImage
            alt={img.alt}
            image={img.gatsbyImageData}
            title={img.title}
          />
        </ImgLayout>
        <Article>
          <QuoteMarks alt="quotation-marks" />
          <Quote2> {t('home.quote.description')}</Quote2>
          <Text5>{`${t('home.quote.name')} - ${t('home.quote.course')}`}</Text5>
        </Article>
      </Section>
    </Layout>
  );
};

export default ImageQuote;
