import { updateDataLayer } from '../../lib/datalayer';

function emitNavigationEvent(direction: string) {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click icon',
    eventLabel: `campus module::navigation arrow::${direction}`
  });
}

function emitShowCampusEvent(campusCode: string) {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click image',
    eventLabel: `campus module::${campusCode}`
  });
}

function emitShowAllCampusesEvent() {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click link',
    eventLabel: 'campus module::all campuses'
  });
}

export { emitNavigationEvent, emitShowAllCampusesEvent, emitShowCampusEvent };
