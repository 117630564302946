import styled, { css } from 'styled-components';
import config from '../config';

const Carousel = styled.section`
  display: flex;
  flex-direction: column;
  margin: 3rem 4.8rem 4.8rem;
  padding: 0 4.8rem;
  position: relative;

  & > section {
    align-items: center;
    display: flex;
    margin-top: 2.4rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-top: 1.6rem;
    margin: 1.6rem 0 4.8rem;
    padding: 0;
  }
`;

const DivArrow = styled.div`
  & > article {
    background-color: ${config.palette.darkBlueTransparent('0.1')};
    border-radius: 100%;
    cursor: pointer;
    padding: 1.6rem;
    position: absolute;
    top: calc(50% - 4.8rem);
    user-select: none;

    &:first-child {
      left: -3.2rem;
    }

    &:last-child {
      right: -3.2rem;
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    display: flex;

    & > article {
      background-color: initial;
      padding: 0;
      position: static;

      & > img {
        margin-left: 2.4rem;
      }
    }
  }
`;

const ArticleLine = styled('article')<{ totalItems: number }>`
  background-color: ${config.palette.darkBlueTransparent('0.4')};
  height: 0.2rem;
  margin-right: 0.4rem;
  width: ${(props): number => 100 / props.totalItems}%;
  &.active {
    background-color: ${config.palette.darkBlue};
  }
`;

const LinkCampus = styled.a`
  color: ${config.palette.lightBlue};
  font-size: 2rem;
  padding-bottom: 0.8rem;
  position: relative;
  text-decoration: none;

  &:hover::after {
    transform: scaleX(1);
  }

  &::after {
    background-color: ${config.palette.lightBlue};
    bottom: 0;
    content: '';
    content: '';
    height: 0.2rem;
    position: absolute;
    right: 0;
    transform: scaleX(0.4);
    transition: transform 0.5s;
    width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    border-radius: 0.6rem;
    border: 0.2rem solid ${config.palette.lightBlue};
    color: ${config.palette.lightBlue};
    display: block;
    font-family: ${config.fonts.normal};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 130%;
    padding: 1.5rem;
    text-align: center;
    transition: 0.3s;

    &:active {
      border-color: ${config.palette.lightBlueHover};
    }

    &::after {
      display: none;
    }
  }
`;

const ImageDiv = styled('section')<{ translateValue: number }>`
  border-radius: 0.4rem;
  height: 32rem;
  overflow: hidden;
  position: relative;

  div {
    display: flex;
    width: 100%;
    height: 100%;
    transform: translateX(-${(props): number => props.translateValue}px);
    transition: transform 1s ease-out;

    a {
      display: block;
      height: 100%;
      min-width: 100%;
      position: relative;

      img {
        height: auto;
        width: 100%;
      }
    }
  }
  @media (max-width: ${config.resolutions.mobileL}) {
    height: 21.2rem;

    div {
      top: 0px;

      a {
        display: block;
        height: 100%;
        width: 100%;
        position: relative;

        img {
          position: relative;
          display: block;
          height: 100%;
          top: 0px;
        }
      }
    }
  }
`;

const TextInImageArticle = styled.article`
  background-color: ${config.palette.white};
  border-radius: 0.4rem;
  left: 1.6rem;
  padding: 0.8rem 1.6rem;
  position: absolute;
  top: 46rem;

  & > p {
    color: ${config.palette.darkBlue};
    font-size: 1.4rem;
    line-height: 150%;
    text-transform: capitalize;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    bottom: 0.4rem;
    left: 0.4rem;
  }
`;

const LayoutOurCampuses = css`
  padding-bottom: 6.4rem;
  padding-top: 6.4rem;
  text-align: center;

  @media (max-width: ${config.resolutions.mobileL}) {
    text-align: left;
  }
`;

export {
  Carousel,
  DivArrow,
  ArticleLine,
  LinkCampus,
  ImageDiv,
  TextInImageArticle,
  LayoutOurCampuses
};
