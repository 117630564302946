import { updateDataLayer } from '../../lib/datalayer';

function emitCareerServicesEvent() {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: 'access ironhack::career services'
  });
}

function emitTestimonialsEvent() {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: 'access ironhack::testimonials'
  });
}

function emitFinancingOptionsEvent() {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'interest',
    eventAction: 'click button',
    eventLabel: 'access ironhack::financing options'
  });
}

export {
  emitCareerServicesEvent,
  emitTestimonialsEvent,
  emitFinancingOptionsEvent
};
