import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Flex } from '@chakra-ui/react';

import CourseCampusSelector from '../CourseCampusSelector';
import { getLinkByPageName, getCourseCampusLink } from '../../lib/links';

type Props = {
  scrollToCourses: () => void;
};

const CtaHome = (props: Props): JSX.Element => {
  const { t, i18n } = useTranslation();

  const handleSubmission = (course: string, campus: string): void => {
    if (!course && !campus) {
      return props.scrollToCourses();
    }

    // REFACTOR: Fix when the navigate bug is solved
    if (!course) {
      window.location.href = getLinkByPageName(campus, i18n.language);
      return;
    }
    if (!campus) {
      window.location.href = getLinkByPageName(course, i18n.language);
      return;
    }
    window.location.href = getCourseCampusLink(course, campus, i18n.language);
  };

  return (
    <Flex
      alignItems="center"
      direction="column"
      mt={[null, null, null, 6]}
      mx={3}
    >
      <Text
        as="h1"
        color="darkBlue.100"
        dangerouslySetInnerHTML={{
          __html: t('home:home.newMainHeadline.title'),
        }}
        fontSize={['3.8rem', null, null, '5.6rem']}
        maxWidth={[null, null, null, '106.8rem']}
        sx={{ '&>span': { color: 'electricBlue.100' } }}
        textAlign="center"
        textStyle="jumbo"
      />
      <Text
        color="darkBlue.64"
        maxWidth={[null, null, null, '92.8rem']}
        mb={[3, null, null, 4]}
        mt={2}
        textAlign="center"
        textStyle="body3"
      >
        {t('home:home.newMainHeadline.subtitle')}
      </Text>
      <CourseCampusSelector onSubmit={handleSubmission} />
    </Flex>
  );
};

export default CtaHome;
