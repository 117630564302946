import styled from 'styled-components';
import config from '../../../config';

export const Component = styled.em`
  color: ${config.palette.darkBlue};
  font-family: ${config.fonts.normal};
  font-style: italic;
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 125%;
  margin: 0;

  &:after {
    content: '"';
  }

  @media (max-width: ${config.resolutions.tablet}) {
    font-size: 2.4rem;
    line-height: 130%;
  }
  @media (max-width: ${config.resolutions.mobileS}) {
    font-size: 2rem;
  }
`;
