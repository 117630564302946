import styled, { css } from 'styled-components';

import config from '../config';

export default styled.section`
  background-color: ${(props) => props.theme.background};
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  margin-top: 3.2rem;
  padding: 3.2rem 4.8rem;
  width: calc(50% - 3.2rem);

  p {
    margin-top: 1.6rem;
    margin-bottom: 3.2rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  a {
    display: block;
    width: 100%;
    text-align: center;
    color: ${(props) => props.theme.color};
    border: 0.2rem solid ${(props) => props.theme.color};

    &:hover {
      border-color: ${(props) => props.theme.hover};
    }
  }

  &.access-ironhack-text-image {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > img {
      width: 47%;
      border-radius: 0.6rem;
    }
  }

  &:last-child {
    flex-wrap: wrap;
    flex-direction: inherit;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    margin-top: 1.6rem;
    padding: 2.4rem;

    &.access-ironhack-text-image {
      flex-direction: column-reverse;

      & > img {
        width: 100%;
      }
    }
  }
`;

const ItemHome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  width: calc(50% - 3.2rem);

  & > p {
    margin-top: 1.6rem;
    margin-bottom: 3.2rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }

  & > a {
    display: block;
    width: 100%;
    text-align: center;
    color: ${config.palette.cyan};
    border: 2px solid ${config.palette.cyan};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    margin-top: 1.6rem;
  }
`;

const LayoutAccessIronhack = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 6.4rem;

  h2 {
    width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding: 0;
    margin-bottom: 3.2rem;

    h2 {
      padding: 0 2.4rem;
    }
  }
`;

export { ItemHome, LayoutAccessIronhack };
