import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from '@chakra-ui/react';

import {
  emitCareerServicesEvent,
  emitTestimonialsEvent,
  emitFinancingOptionsEvent,
} from './ga_events';
import { getLinkByPageName } from '../../lib/links';
import Layout from '../atoms/layout';
import { Text1, Text3 } from '../atoms/body';
import { Title2 } from '../atoms/headers';
import { LinkButton } from '../atoms/buttons';
import { theme } from './theme';
import Article, { ItemHome, LayoutAccessIronhack, ImageLayout } from './styles';
import { TDatoCmsImage } from '../../types/datocms';

type Props = {
  img: TDatoCmsImage;
};

const AccessToIronhackHome = (props: Props): ReactElement => {
  const { img } = props;
  const { t } = useTranslation(['home']);

  return (
    <Layout extend={LayoutAccessIronhack}>
      <Title2 as="h2">{t('home.accessIronhack.title')}</Title2>
      <Article theme={theme.orange}>
        <Text1 as="h3">{t('home.accessIronhack.01.title')}</Text1>
        <Text3 as="p">{t('home.accessIronhack.01.description')}</Text3>
        <LinkButton
          href={getLinkByPageName('careerServices')}
          onClick={emitCareerServicesEvent}
          size="m"
        >
          {t('home.accessIronhack.01.cta')}
        </LinkButton>
      </Article>
      <Article theme={theme.darkBlue}>
        <Text1 as="h3">{t('home.accessIronhack.02.title')}</Text1>
        <Text3 as="p">{t('home.accessIronhack.02.description')}</Text3>
        <LinkButton
          href={getLinkByPageName('alumni')}
          onClick={emitTestimonialsEvent}
          size="m"
        >
          {t('home.accessIronhack.02.cta')}
        </LinkButton>
      </Article>
      <Article className="access-ironhack-text-image" theme={theme.cyan}>
        <ItemHome>
          <Text1 as="h3">{t('home.accessIronhack.03.title')}</Text1>
          <Text3 as="p">{t('home.accessIronhack.03.description')}</Text3>
          <LinkButton
            href={getLinkByPageName('financing')}
            onClick={emitFinancingOptionsEvent}
            size="m"
          >
            {t('home.accessIronhack.03.cta')}
          </LinkButton>
        </ItemHome>
        <Box width={['100%', null, '47%', '47%']}>
          <GatsbyImage
            alt={`Ironhack ${t('home.accessIronhack.03.title')}`}
            image={img.gatsbyImageData}
            title={img.title}
          />
        </Box>
      </Article>
    </Layout>
  );
};

export default AccessToIronhackHome;
