import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import classNames from 'classnames';
import Img from 'gatsby-image';

import {
  emitNavigationEvent,
  emitShowAllCampusesEvent,
  emitShowCampusEvent
} from './ga_events';
import DatoContactInfo from '../../services/datoCampusContactInformation';
import { getLinkByPageName } from '../../lib/links';
import { capitalize } from '../../lib/utils';
import Layout from '../atoms/layout';
import { Title1 } from '../atoms/headers';
import LeftArrow from '../../../static/assets/icons/arrows/arrow-left.svg';
import RightArrow from '../../../static/assets/icons/arrows/arrow-right.svg';
import {
  Carousel,
  DivArrow,
  ArticleLine,
  LinkCampus,
  ImageDiv,
  TextInImageArticle,
  LayoutOurCampuses
} from './styles';

interface Props {
  t: TFunction;
  locale: string;
}

interface SliderProps {
  city: string;
  code: string;
  img: string;
  url: string;
}

const OurCampuses: React.SFC<any> = (props: Props) => {
  const { t, locale } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateValue, setTranslateValue] = useState(0);
  const campusContactInfo = new DatoContactInfo(
    locale
  ).contactInformationByLanguage();
  const campuses = campusContactInfo.map(info => ({
    city: info.campus.description,
    code: info.campus.code,
    img: info.sliderImages[0].fluid,
    url: getLinkByPageName(`campus${capitalize(info.campus.code)}`)
  }));
  const imageUrls = campuses.map(campus => campus.img);
  const imgLength = imageUrls.length - 1;

  let width: number;
  const getNextSlide = (pager: number): { page: number; imgWidth: number } => {
    const nextSlider = currentIndex + pager;
    if (nextSlider < 0) {
      return {
        page: imgLength,
        imgWidth: width * imgLength
      };
    }
    if (nextSlider === imageUrls.length) {
      return { page: 0, imgWidth: 0 };
    }
    return { page: nextSlider, imgWidth: translateValue + width * pager };
  };

  const changeSlide = (pager: number): void => {
    if (!width) {
      const imgSlider = document.getElementById('imgSlider');
      if (imgSlider) {
        width = imgSlider.offsetWidth / 2;
      }
    }
    const { page, imgWidth } = getNextSlide(pager);
    setCurrentIndex(page);
    setTranslateValue(imgWidth);
  };

  return (
    <Layout extend={LayoutOurCampuses}>
      <Title1 as="h2">{t('home:home.campus.title')}</Title1>
      <Carousel>
        <ImageDiv id="imgSlider" translateValue={translateValue}>
          <div>
            {campuses.map(
              (campus: SliderProps): JSX.Element => (
                <a
                  onClick={() => emitShowCampusEvent(campus.code)}
                  href={campus.url}
                  key={`img-slider-${campus.code}`}
                >
                  <Img fluid={campus.img} />
                  <TextInImageArticle>
                    <p>Ironhack {campus.city}</p>
                  </TextInImageArticle>
                </a>
              )
            )}
          </div>
        </ImageDiv>
        <section>
          {campuses.map(
            (campus: SliderProps, i: number): JSX.Element => (
              <ArticleLine
                key={`img-slider-page-${campus.code}`}
                totalItems={campuses.length}
                className={classNames({ active: i === currentIndex })}
              />
            )
          )}
          <DivArrow>
            <article
              onClick={(): void => {
                emitNavigationEvent('left');
                changeSlide(-1);
              }}
            >
              <LeftArrow />
            </article>
            <article
              onClick={(): void => {
                emitNavigationEvent('right');
                changeSlide(1);
              }}
            >
              <RightArrow />
            </article>
          </DivArrow>
        </section>
      </Carousel>
      <LinkCampus
        onClick={emitShowAllCampusesEvent}
        href={getLinkByPageName('allCampuses')}
      >
        {t('home:home.campus.cta')}
      </LinkCampus>
    </Layout>
  );
};

export default withTranslation()(OurCampuses);
