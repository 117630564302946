import config from '../config';

const theme = {
  cyan: {
    background: config.palette.cyanTransparent('0.1'),
    hover: config.palette.cyanTransparent('0.6'),
    color: config.palette.cyan
  },
  darkBlue: {
    background: config.palette.darkBlueTransparent('0.1'),
    hover: config.palette.darkBlueTransparent('0.6'),
    color: config.palette.darkBlue
  },
  orange: {
    background: config.palette.orangeTransparent('0.1'),
    hover: config.palette.orangeTransparent('0.6'),
    color: config.palette.orange
  }
};

export { theme };
