import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import _ from 'lodash/fp';

import GlobalStyles from '../components/GlobalStyles';
import HeaderHome from '../components/HeaderHome';
import CtaHome from '../components/CtaHome';
import StudentCompanies from '../components/StudentCompanies';
import HomeTestimonial from '../components/HomeTestimonial';
import OurCampuses from '../components/OurCampuses';
import Courses from '../components/Courses';
import WhyIronhack from '../components/Grid';
import WhyIronhackItem from '../components/WhyIronhackItem';
import AccessToIronhackHome from '../components/AccessToIronhackHome';
import Partner from '../components/Partner';
import Footer from '../components/Footer';
import WhyIronhackItems from '../components/interfaces/WhyIronhackItem';
import TransformIcon from '../../static/assets/icons/why-ironhack/transform.svg';
import AccessIcon from '../../static/assets/icons/why-ironhack/access.svg';
import LearnIcon from '../../static/assets/icons/why-ironhack/learn.svg';
import OutcomesIcon from '../../static/assets/icons/why-ironhack/outcomes.svg';
import { processDatoCmsAssests } from '../services/datoUtilities';
import { TDatoCmsGraphQlAssets } from '../types/datocms';
import { PageContext } from '../context/PageContext';
import useScroll from '../hooks/useScroll';

type HomeData = {
  allDatoCmsAssetsByPage: TDatoCmsGraphQlAssets;
};

type HomeProps = PageProps<HomeData, PageContext>;

const whyIronhack: WhyIronhackItems[] = [
  {
    image: TransformIcon,
    title: 'Transform your career',
    text: 'Whether you need to get a job in tech or launch your own business, Ironhack will help you achieve your goals.',
  },
  {
    image: AccessIcon,
    title: 'Access a Global Network',
    text: 'Join a global network of +2500 graduates and professionals across our 9 campuses around the world.',
  },
  {
    image: LearnIcon,
    title: 'Learn by Creating',
    text: "Learn by working on amazing projects using the tools and workflows you'll encounter in a real work environment.",
  },
  {
    image: OutcomesIcon,
    title: 'Outcomes First',
    text: 'Our graduates get hired in less than 3 months through our +600 international network of hiring partners.',
  },
];

const IndexPage = (props: HomeProps): ReactElement => {
  const { data } = props;
  const assets = processDatoCmsAssests(data.allDatoCmsAssetsByPage);
  const [scrollToCourses, coursesRef] = useScroll();

  return (
    <>
      <GlobalStyles />
      <HeaderHome pageName="home">
        <CtaHome scrollToCourses={scrollToCourses} />
      </HeaderHome>
      <StudentCompanies />
      <HomeTestimonial img={_.getOr({}, 'cohort', assets)} />
      <Courses ref={coursesRef} title="Our Courses" />
      <OurCampuses />
      <WhyIronhack title="Why should you study at Ironhack?">
        {whyIronhack.map((item, index) => (
          <WhyIronhackItem
            image={item.image}
            key={index}
            text={item.text}
            title={item.title}
          />
        ))}
      </WhyIronhack>
      <AccessToIronhackHome img={_.getOr({}, 'week', assets)} />
      <Partner
        subtitle="Ironhack is committed to helping you secure a new job after finishing your bootcamp. With your hard-work and our help, we’re confident that you can get hired by a fantastic company."
        title="Become a partner"
        viewButton
      />
      <Footer page="" showLinks />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    allDatoCmsAssetsByPage(
      filter: { page: { eq: "home" }, locale: { eq: $locale } }
    ) {
      nodes {
        page
        locale
        assets {
          gatsbyImageData(layout: FULL_WIDTH)
          title
          alt
          customData
          basename
        }
      }
    }
  }
`;

export default IndexPage;
