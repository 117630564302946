import styled, { css } from 'styled-components';

import config from '../config';

const hexagon = `data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%221222%22%20height%3D%221211%22%20viewBox%3D%220%200%201222%201211%22%3E%0A%3Cpath%20fill%3D%22%231E2434%22%20fill-rule%3D%22evenodd%22%20d%3D%22M156.734396%2C1011.12745%20C158.728123%2C1018.50046%20161.624314%2C1023.97957%20164.538232%2C1028.92122%20C168.571847%2C1035.76173%20172.930575%2C1041.20614%20177.589077%2C1045.93228%20C187.614721%2C1056.10349%20199.035313%2C1063.5127%20220.808952%2C1069.35492%20L734.272177%2C1219.30068%20C773.471958%2C1229.81862%20799.336948%2C1225.55814%20828.110294%2C1196.53227%20L1199.21896%2C822.166713%20C1227.9923%2C793.140848%201223.46434%2C762.62089%201212.9045%2C722.942513%20L1063.29756%2C202.747051%20C1061.99056%2C197.913624%201060.92879%2C194.136909%201059.3407%2C190.729651%20C1055.79568%2C183.123802%201051.9986%2C177.752503%201046.2205%2C171.923696%20C1037.85394%2C163.483708%201029.53423%2C155.180913%201005.8213%2C148.818348%20L504.026754%2C-0.265744356%20C464.826972%2C-10.7836829%20423.539176%2C-18.3722524%20394.76583%2C10.6536123%20L22.7114475%2C387.318937%20C-6.06189876%2C416.344802%20-4.02260872%2C456.131149%206.53722988%2C495.809527%20L156.734396%2C1011.12745%20Z%22%20transform%3D%22rotate%2837%20610.199%20607.435%29%22%2F%3E%0A%3C%2Fsvg%3E`;

export default styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 6.4rem;

  & > img {
    width: 75%;
    margin-right: 3.2rem;
    box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.198992);
    border-radius: 0.6rem;
    z-index: 1;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column-reverse;

    & > img {
      margin-right: 0;
      margin-top: 4.8rem;
      width: 100%;
    }
  }
`;

export const ImgLayout = styled.section`
  width: 65%;
  margin-right: 3.2rem;

  & > div {
    box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.198992);
    border-radius: 0.6rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-direction: column-reverse;
    width: 100%;
    margin-right: 0;
    margin-top: 4.8rem;
  }
`;

export const Article = styled.article`
  width: 35%;
  padding: 1.6rem;
  padding-right: 2.4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.08);
  border-radius: 0.6rem;
  height: 100%;
  background-color: ${config.palette.white};

  & > svg {
    position: absolute;
    width: 48px;
    height: 48px;
  }

  & > em {
    margin-top: 2.4rem;
    margin-bottom: 11rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;

    & > em {
      margin-top: 1.6rem;
      margin-bottom: 1.6rem;
    }
  }
`;

const LayoutQuote = css`
  background: url(${hexagon}) -25rem 13rem no-repeat,
    url(${hexagon}) 158rem 0rem no-repeat;
  background-size: 60rem;
  padding-bottom: 9.3rem;

  @media (max-width: ${config.resolutions.desktopM}) {
    background-position: -38rem 22rem, 135rem 50%;
  }
  @media (max-width: ${config.resolutions.desktopS}) {
    background-position: -40rem 200%, 113rem 50%;
    background-size: 60rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    background-position: bottom;
    background-position-y: 24rem;
    background-position-x: 16rem;
    background-size: 44rem;
    padding-bottom: 5.6rem;
  }
`;

export { LayoutQuote };
